<template>
  <b-container>
    <b-card>
      <validation-observer
        #default="{ invalid }"
      >
        <b-form>
          <b-form-group
            label="Usuário"
            label-for="user"
          >
            <validation-provider
              #default="{ errors }"
              name="arquivo"
              rules="required"
            >
              <b-form-select
                id="user"
                v-model="props.userId"
                :options="usersOptions"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Nome do Contrato"
            label-for="contract-name"
          >
            <validation-provider
              name="arquivo"
              rules="required"
            >
              <b-form-input
                v-model="props.contractName"
                placeholder="Nome do Contrato"
              />
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Descrição"
            label-for="description"
          >
            <validation-provider
              name="arquivo"
              rules="required"
            >
              <b-form-input
                v-model="props.description"
                placeholder="Descrição"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Arquivo do Contrato"
            label-for="contract-file"
          >
            <validation-provider
              #default="{ errors }"
              name="arquivo"
              rules="pdf|required"
            >
              <b-form-file
                v-model="props.file"
                :state="errors.length > 0 ? false : null"
                placeholder="Arraste ou selecione um arquivo"
                drop-placeholder="Arraste o arquivo aqui."
                browse-text="Procurar"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button
            :disabled="invalid"
            variant="primary"
            @click="importContract"
          >
            Importar
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </b-container>
</template>

<script>
import {
    BFormSelect, BFormInput, BForm, BFormGroup, BButton, BContainer, BFormFile, BCard,
} from 'bootstrap-vue';
import {
    ValidationProvider,
    ValidationObserver,
} from 'vee-validate';
import AdminIrisService from '@/service/online_registration/online-registration';
import '@validations';

export default {
    components: {
        BFormSelect,
        BFormInput,
        BForm,
        BFormGroup,
        BButton,
        BContainer,
        BFormFile,
        BCard,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            users: [],
            props: {
                userId: null,
                contractName: '',
                description: '',
                file: null,
            },
        };
    },

    computed: {
        usersOptions() {
            const options = this.users.map(item => ({ value: item.user_id, text: `${item.name}` }));
            options.unshift({ value: null, text: 'Selecione um Usuário' });
            return options;
        },
    },

    async mounted() {
        const { data } = (await AdminIrisService.getAllUsers());
        this.users = data.data;
    },

    methods: {
        async importContract() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente importar esse contrato?');
            if (!isConfirmed) return;
            const { status } = await AdminIrisService.importContract(this.props);
            if (status === 204) { this.modalSuccess('Contrato importado com sucesso'); } else { this.modalError('Não foi possível importar esse contrato'); }
        },
    },
};
</script>
